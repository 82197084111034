<template>
  <v-scroll-y-transition>
    <v-footer v-if="footer" inset dark color="#004691" class="text-right">
      <v-img
        :src="require('@assets/ennova_logotype_negative.svg')"
        contain
        max-height="36"
        class="my-1"
      />
    </v-footer>
  </v-scroll-y-transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Footer',
  computed: {
    ...mapState('ui', ['footer']),
  },
}
</script>
